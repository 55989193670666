export const names: any = {
    "05": "Openair",
    "06": "BaseSmart",
    "07": "Atmosphere",
    "32": "Waterfall",
    "2213": "Cityair",
    "51": "KivSmart",
    "43": "Vector",
    "44": "Cityair 500",
    "24": "SmartEx",
    "07C": "Atmosphere v2",
};
